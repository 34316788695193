import React, { Component } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

export default class Contact extends Component {

    render() {

        return (
            <section className="contact" id="contacto">
                <h3 className="contact__title">Contáctanos</h3>
                <p className="contact__subtitle--mobile">Cotización</p>
                <p className="contact__subtitle">Solicite una cotización gratuita aquí</p>
                <form className="contact__form">
                    <div className="contact__form__left">
                        <select name="" id="">
                            <option value="">Tipo de nave</option>
                            <option value="">Cajas secas</option>
                            <option value="">Tractocamiones</option>
                            <option value="">Dollies</option>
                            <option value="">Fletes locales</option>
                            <option value="">Fletes foraneos</option>
                        </select>
                        <select name="" id="">
                            <option value="">Cantidad</option>
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                            <option value="">5+</option>
                        </select>
                        <select name="" id="">
                            <option value="">Estado</option>
                            <option value="">Aguascalientes</option>
                            <option value="">Baja California</option>
                            <option value="">Baja California Sur</option>
                            <option value="">Campeche</option>
                            <option value="">Chiapas</option>
                            <option value="">Chihuahua</option>
                            <option value="">Coahuila</option>
                            <option value="">Colima</option>
                            <option value="">Ciudad de México</option>
                            <option value="">Durango</option>
                            <option value="">Guanajuato</option>
                            <option value="">Guerrero</option>
                            <option value="">Hidalgo</option>
                            <option value="">Jalisco</option>
                            <option value="">Estado de México</option>
                            <option value="">Michoacán</option>
                            <option value="">Morelos</option>
                            <option value="">Nayarit</option>
                            <option value="">Nuevo León</option>
                            <option value="">Oaxaca</option>
                            <option value="">Puebla</option>
                            <option value="">Querétaro</option>
                            <option value="">Quintana Roo</option>
                            <option value="">San Luis Potosí</option>
                            <option value="">Sinaloa</option>
                            <option value="">Sonora</option>
                            <option value="">Tabasco</option>
                            <option value="">Tamaulipas</option>
                            <option value="">Tlaxcala</option>
                            <option value="">Veracruz</option>
                            <option value="">Yucatán</option>
                            <option value="">Zacatecas</option>
                            <option value="">Estado</option>
                        </select>
                        <select name="" id="">
                            <option value="">Duracion</option>
                            <option value="">3 meses</option>
                            <option value="">4 meses</option>
                            <option value="">5+ meses</option>
                        </select>
                        <input type="text" placeholder="Razon social"/>
                    </div>
                    <div className="contact__form__right">
                        <input type="text" placeholder="Celular o correo"/>
                        <textarea placeholder="Mensaje: (opcional)"></textarea>
                        <button>
                            Solicitar
                            <ArrowIcon className="arrow-icon"></ArrowIcon>
                        </button>
                    </div>
                </form>
            </section>
        );
    }
}