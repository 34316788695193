import React, { Component } from 'react';
import Image1 from '../../assets/img/tr_imageCajaSeca1.jpg';
import Image2 from '../../assets/img/tr_imageW900_2.jpg';
import Image3 from '../../assets/img/truck_new.jpg';
import Image4 from '../../assets/img/highway-3392100_1920.jpg';
import Image5 from '../../assets/img/truck-2920533_1920.jpg';
import Image6 from '../../assets/img/truck_new.jpg';
import Image7 from '../../assets/img/highway-3392100_1920.jpg';

export default class OurAllies extends Component {

    render() {

        return(
            <section className="services-full">
                <h2 className="services-full__title">Nuestros</h2>
                <h3 className="services-full__subtitle">Servicios</h3>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.
                </p>

                <div className="services-full__services-grid">
                    <div className="services-full__services-grid__card">
                        <img src={ Image1 } alt="1"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Renta de cajas secas</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Descubra la solución perfecta para sus necesidades de transporte con nuestro servicio de arrendamiento de cajas secas en Transrental MX. Ofrecemos una amplia disponibilidad de cajas secas de diversos tipos y modelos, adaptados para cubrir eficientemente sus requerimientos. Confíe en nosotros para proporcionarle la solución ideal que impulsa la eficacia y confiabilidad en cada carga.
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image2 } alt="2"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Renta de tractocamiones</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Explore la excelencia en el transporte de carga con nuestro servicio de arrendamiento de tractocamiones en Transrental MX. Contamos con una extensa flotilla de tractocamiones de última generación, listos para satisfacer sus demandas específicas. Desde modelos estándar hasta opciones especializadas, ofrecemos una variedad que garantiza la elección adecuada para optimizar su operación. Confíe en nosotros para el arrendamiento de tractocamiones que impulsa el rendimiento y la eficiencia en cada viaje.   
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image3 } alt="3"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Renta de plataformas</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Descubra la versatilidad y confiabilidad en el transporte de carga con nuestro servicio de arrendamiento de plataformas en Transrental MX. Ofrecemos una amplia gama de plataformas para adaptarse a sus necesidades específicas. Desde plataformas estándar hasta modelos especializados como camas bajas; nuestra flotilla garantiza opciones flexibles y eficientes para el traslado de cargas diversas. Brindamos soluciones seguras y eficaces para sus proyectos logísticos.
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image4 } alt="4"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Renta de dollies</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image5 } alt="5"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Fletes locales</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image6 } alt="6"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Fletes foraneos</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                            </p>
                        </div>
                    </div>
                    <div className="services-full__services-grid__card">
                        <img src={ Image7 } alt="7"/>
                        <div className="services-full__services-grid__card__info">
                            <h4 className="services-full__services-grid__card__info--title">Mulitas o patieros</h4>
                            <p className="services-full__services-grid__card__info--description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}