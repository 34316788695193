import React, { Component } from 'react';
import Menu from './Menu';

export default class MenuWithoutSlider extends Component {

    constructor() {
        super();

        this.getBackgroundClass = this.getBackgroundClass.bind( this );
    }

    getBackgroundClass() {
        
        const { pathname } = window.location;

        switch( pathname ) {
            case '/servicios':
                return 'main-header-without-slider-container--services';
                break;
            case '/quienes-somos':
                return 'main-header-without-slider-container--who-we-are';
                break;
            case '/flotillas':
                return 'main-header-without-slider-container--flotilla';
                break;
            case '/contactanos':
                return 'main-header-without-slider-container--contact-us';
                break;
        }

    }

    render() {

        return (
            <div className={ `main-header-without-slider-container ${ this.getBackgroundClass() }` }>
                <Menu></Menu>
            </div>
        );
    }
}