import React from 'react';
import Header from './components/layouts/Header';
import WhoWeAre from './components/sections/who-we-are';
import WhoWeAreFull from './components/sections/WhoWeAreFull';
import OurMisionVision from './components/sections/OurMisionVision';
import OurAllies from './components/sections/OurAllies';
import ServicesFull from './components/sections/ServicesFull';
import ContactFull from './components/sections/ContactFull';
import Flotillas from './components/sections/Flotillas';
import Counters from './components/sections/Counters';
import Services from './components/sections/Services';
import ClientsBar from './components/sections/ClientsBar';
import Contact from './components/sections/Contact';
import Footer from './components/layouts/Footer';
import './assets/sass/main.scss';
import { Route } from 'react-router-dom';
import WhatsApp from './assets/img/whats_logo.png';

function App() {
  return (
    <div className="App">
      <img src={ WhatsApp } alt="WhatsApp" className="wp-logo" onClick={ () => window.open('https://wa.me/5213338497673?text=Hola!%20Me%20gustaría%20recibir%20información', '_blank') }/>
      <Header></Header>
      <Route exact path="/">
        <WhoWeAre></WhoWeAre>
        <Counters></Counters>
        <Services></Services>
        <ClientsBar></ClientsBar>
      </Route>
      <Route exact path="/servicios">
        <ServicesFull></ServicesFull>
      </Route>
      <Route exact path="/quienes-somos">
        <WhoWeAreFull></WhoWeAreFull>
        <OurMisionVision></OurMisionVision>
        <OurAllies></OurAllies>
      </Route>
      <Route exact path="/flotillas">
        <Flotillas></Flotillas>
      </Route>
      <Route exact path="/contactanos">
        <ContactFull></ContactFull>
      </Route>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
