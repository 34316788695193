import React, { Component } from 'react';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as Logo } from '../../assets/img/logo_nuevo.svg';

export default class MobileMenuBar extends Component {

    constructor() {
        super();

        this.state = {
            scrollY: 0
        };

        window.addEventListener('scroll', e => {
            this.setState({ scrollY: window.pageYOffset });

            document.getElementsByClassName('info-bar')[0].marginTop
                = window.pageYOffset >= 50 ? '50px' : '0'
        });

    }

    render() {

        return (
            <nav className={ `mobile-menu-mar ${ this.state.scrollY >= 50 ? 'sticky-menu' : '' }` }>
                <MenuIcon onClick={ () => this.props.openMenu() }></MenuIcon>
                <Logo className="mobile-logo"></Logo>
            </nav>
        );
    }
}