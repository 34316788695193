import React, { Component } from 'react';
import VMediaImage from '../../assets/img/vmedia.png';
import WalmartImage from '../../assets/img/walmart.png';
import CoralImage from '../../assets/img/coral.png';

export default class ClientsBar extends Component {

    render() {
        
        return (
            <section className="clients-bar">
                <img src={ VMediaImage } alt="VMedia"/>
                <img src={ WalmartImage } alt="Walmart"/>
                <img src={ CoralImage } alt="Coral"/>
            </section>
        );
    }
}