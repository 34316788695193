import React, { Component } from 'react';

export default class OurMisionVision extends Component {

    render() {

        return(
            <section className="our-mision-vision">
                <h2 className="our-mision-vision__title">Nuestra</h2>
                <h3 className="our-mision-vision__subtitle">Misión y visión</h3>

                <div className="cards-grid">
                    <div className="cards-grid__card">
                        <h4 className="cards-grid__card__title">POLÍTICA DE CALIDAD</h4>
                        <p className="cards-grid__card__description">
                            En <strong>TRANSRENTAL, B&B EXPRESS</strong> consideramos las necesidades del cliente como propios, por lo que se trabaja en un sistema que permita superar sus expectativas, mediante la medición y el seguimiento del servicio de transporte.
                            <br/><br/>
                            Estamos comprometidos con la mejora continua de nuestros procesos operativos y administrativos para lo cual todo el personal se compromete y se capacita constantemente.
                            <br/><br/>
                            Por lo que nuestro sistema de calidad se coloca como base en el Sistema ISO 9001:2015 para conocer sus requerimientos de resguardo y elevar el nivel de satisfacción del cliente.
                        </p>
                    </div>
                    <div className="cards-grid__card">
                        <h4 className="cards-grid__card__title">POLÍTICA DE SEGURIDAD</h4>
                        <p className="cards-grid__card__description">
                            En <strong>TRANSRENTAL, B&B EXPRESS</strong> nuestro compromiso con la seguridad se ve reflejado en los altos estándares antes, durante y posterior a nuestros procesos ya que resguardamos la propiedad de nuestros clientes como propia y nos comprometemos a darle seguimiento puntual mientras esta se encuentre bajo nuestro resguardo.
                            <br/><br/>
                            Nos comprometemos a mantener nuestro ambiente laboral libre de riesgos (contrabando, robo, drogas, etc.) mediante un control estricto con personal que labora en la empresa, cuidando que este sea el mejor calificado para brindarle un mejor servicio siempre, oportuno, puntual y seguro.
                        </p>
                    </div>
                    <div className="cards-grid__card">
                        <h4 className="cards-grid__card__title">MISIÓN</h4>
                        <p className="cards-grid__card__description">
                            Nuestra misión es utilizar día con día conceptos de excelencia y dinamismo que impulsen el desarrollo de nuestros clientes y proveedores, utilizando la sinergia de todos los que integramos la empresa, alcanzando la mejora continua en cuanto a costos y servicios con el propósito de que nuestros clientes nos elijan por la calidad y confianza que les brindamos.
                            <br/><br/>
                            Manteniendo todas las unidades en óptimo estado, garantizando el cuidado, el medio ambiente y la economía.
                        </p>
                    </div>
                    <div className="cards-grid__card">
                        <h4 className="cards-grid__card__title">VISIÓN</h4>
                        <p className="cards-grid__card__description">
                            Nuestra visión es colocarnos entre los mejores proveedores en renta de equipo para traslado, almacenaje y transporte para sus mercancías ofreciendo en cada momento seguridad y calidad.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}