import React, { Component } from 'react';
import Menu from '../layouts/Menu';
import { ReactComponent as BoxIcon } from '../../assets/icons/caja.svg';
import { ReactComponent as TracIcon } from '../../assets/icons/tractocamiones.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/icons/plataformas.svg';
import { ReactComponent as DollyIcon } from '../../assets/icons/dolly.svg';
import SlidesJSON from './slides-information.json';

export default class MainHeaderContainer extends Component {

    constructor() {
        super();

        this.state = {
            currentPositionSlider: 0
        }

        this.sliderClickHandler = this.sliderClickHandler.bind( this );
        this.getContainerClass = this.getContainerClass.bind( this );

        this.interval = setInterval( () => {
            const position = this.state.currentPositionSlider;

            this.setState({ currentPositionSlider: position !== 3 ? position + 1 : 0 });
        }, 3000);
    }

    sliderClickHandler( position ) {

        this.setState({ currentPositionSlider: position }, () => clearInterval( this.interval ) );
    }

    componentWillUnmount() {

        clearInterval( this.interval );
    }

    getContainerClass() {

        switch( this.state.currentPositionSlider ) {
            case 0:
                return 'main-header-container--box';
            case 1:
                return 'main-header-container--trac';
            case 2:
                return 'main-header-container--platforms';
            case 3:
                return 'main-header-container--dollies';
            default:
                break;
        }
    }

    render() {
        return (
            <div className={ `main-header-container ${ this.getContainerClass() }` }>
                <Menu></Menu>
                <div className="box-slider">
                    <hr/>
                    <h2 className="box-slider__subtitle">renta</h2>
                    <h1 className="box-slider__title">{ SlidesJSON[ this.state.currentPositionSlider ].title }</h1>
                    <p className="box-slider__description">{ SlidesJSON[ this.state.currentPositionSlider ].description }</p>
                </div>
                <ul className="slider-controls">
                    <li 
                        onClick={ () => this.sliderClickHandler( 0 ) }
                        className={ `slider-controls__item ${ this.state.currentPositionSlider === 0 ? 'slider-controls__item--active' : '' }` }>
                        <BoxIcon></BoxIcon>
                    </li>
                    <li 
                        onClick={ () => this.sliderClickHandler( 1 ) }
                        className={ `slider-controls__item ${ this.state.currentPositionSlider === 1 ? 'slider-controls__item--active' : '' }` }>
                        <TracIcon></TracIcon>
                    </li>
                    <li 
                        onClick={ () => this.sliderClickHandler( 2 ) }
                        className={ `slider-controls__item ${ this.state.currentPositionSlider === 2 ? 'slider-controls__item--active' : '' }` }>
                        <PlatformsIcon></PlatformsIcon>
                    </li>
                    <li 
                        onClick={ () => this.sliderClickHandler( 3 ) }
                        className={ `slider-controls__item ${ this.state.currentPositionSlider === 3 ? 'slider-controls__item--active' : '' }` }>
                        <DollyIcon></DollyIcon>
                    </li>
                </ul>
            </div>
        );
    } 

}