import React, { Component } from 'react';
import Card from '../reusable/Card';
import Card2 from '../reusable/Card2';
import Card3 from '../reusable/Card3';

export default class Services extends Component {

    render() {

        return (
            <section className="services">
                <div className="services__item">
                    <span className="subtitle">Otros</span>
                    <h2 className="title">Servicios</h2>
                    <p>
                        Como tu mejor aliado en logistica, tambien contamos con los siguientes servicios. 
                    </p>
                </div>
                <div className="services__item">
                    <Card></Card>
                </div>
                <div className="services__item">
                    <Card2></Card2>
                </div>
                <div className="services__item">
                    <Card3></Card3>
                </div>
            </section>
        );
    }

}