import React, { Component } from 'react';
import Image from '../../assets/img/who-we-are-image.jpg';

export default class Card3 extends Component {

    render() {

        return (
            <div className="card">
                <div className="card__media">
                    <img src={ Image } alt="Servicio 1"/>
                </div>
                <div className="card__text">
                    <h4 className="card__text__title">Mulitas \ patieros</h4>
                    <p className="card__text__description">
                        3Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem.
                    </p>
                </div>
            </div>
        );
    }

}