import React, { Component } from 'react';
import { ReactComponent as ShipsIcon } from '../../assets/icons/naves.svg';
import { ReactComponent as WorldwideIcon } from '../../assets/icons/worldwide.svg';
import { ReactComponent as SatisfiedIcon } from '../../assets/icons/satisfied.svg';

export default class Conuters extends Component {

    constructor() {
        super();

        this.state = {
            worldwideCounter: 0,
            availableShipsCounter: 0,
            satisfiedClientsCounter: 0
        };

        this.worldwideInterval = null;
        this.availableShipsInterval = null;
        this.satisfiedClientsInterval = null;
        this.initCounters = this.initCounters.bind( this );
        this.scrollHandler = this.scrollHandler.bind( this );

        window.addEventListener('scroll', this.scrollHandler);
        
    }

    scrollHandler() {
        const rect = document.getElementsByClassName('counters')[0].getBoundingClientRect();

        if ( this.state.worldwideCounter === 0 ) {
            if ( rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) ) {
                    
                    this.initCounters();
            } else {
                clearInterval( this.worldwideInterval );
                clearInterval( this.availableShipsInterval );
                clearInterval( this.satisfiedClientsInterval );
                this.setState({
                    worldwideCounter: 0,
                    availableShipsCounter: 0,
                    satisfiedClientsCounter: 0
                });
            } 
        }
    }

    initCounters() {

        this.worldwideInterval = setInterval(() => {
            this.setState({ worldwideCounter: this.state.worldwideCounter + 1 }, () => {
                if ( this.state.worldwideCounter > 2999 ) { clearInterval( this.worldwideInterval ); }
            });
        }, .5);
        
        this.availableShipsInterval = setInterval(() => {
            this.setState({ availableShipsCounter: this.state.availableShipsCounter + 1 }, () => {
                if ( this.state.availableShipsCounter > 213 ) { clearInterval( this.availableShipsInterval ); }
            });
        }, .5);
        
        this.satisfiedClientsInterval = setInterval(() => {
            this.setState({ satisfiedClientsCounter: this.state.satisfiedClientsCounter + 1 }, () => {
                if ( this.state.satisfiedClientsCounter > 213 ) { clearInterval( this.satisfiedClientsInterval ); }
            });
        }, .5);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    render() {

        return (
            <ul className="counters" id="counters">
                <li className="counters__item">
                    <WorldwideIcon className="counters__item__icon"></WorldwideIcon>
                    <div className="counters__item__info">
                        <p>{ this.state.worldwideCounter }</p>
                        <span>viajes hechos</span>
                    </div>
                </li>
                <li className="counters__item">
                    <ShipsIcon className="counters__item__icon"></ShipsIcon>
                    <div className="counters__item__info">
                        <p>{ this.state.availableShipsCounter }</p>
                        <span>naves disponibles</span>
                    </div>
                </li>
                <li className="counters__item">
                    <SatisfiedIcon className="counters__item__icon"></SatisfiedIcon>
                    <div className="counters__item__info">
                        <p>{ this.state.satisfiedClientsCounter }</p>
                        <span>clientes satisfechos</span>
                    </div>
                </li>
            </ul>
        );
    }
}
