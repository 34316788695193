import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class MobileMenu extends Component {

    constructor( props ) {
        super();

        this.state = {
            showSubmenu: false
        }

        this.menuContainerClickHandler = this.menuContainerClickHandler.bind( this );
    }

    menuContainerClickHandler( e ) {

        if ( e.target.className === 'mobile-menu-container' ) {

            this.props.closeMenu();
        }
    }

    menuItemClickHandler( route ) {

        this.props.closeMenu();
        this.props.history.push( route );
    }

    render() {
        return (
            <div className="mobile-menu-container" onClick={ e => this.menuContainerClickHandler( e ) }>
                <ul className="menu-list">
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/inicio' ) }>
                        <p>Inicio</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/servicios' ) }>
                        <p>Servicios</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/quienes-somos' ) }>
                        <p>Quienes somos</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/flotillas' ) }>
                        <p>Flotilla</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/contactanos' ) }>
                        <p>Contacto</p>
                    </li>
                </ul>
            </div>
        );
    }
    
}

export default withRouter( MobileMenu );