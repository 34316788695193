import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/img/logo_nuevo.svg';
import VerifiedImage from '../../assets/img/verified.png';

class Menu extends Component {

    constructor() {
        super();

        this.state = {
            pathname: '/'
        };

        this.menuItemClickHandler = this.menuItemClickHandler.bind( this );
    }

    componentDidMount() {

        this.setState({ pathname: window.location.pathname })
    }
    
    menuItemClickHandler( path ) {
        
        this.props.history.push( path );
        this.setState({ pathname: window.location.pathname });
    }

    render() {
        const { pathname } = this.state;

        return (
            <div className="menu-container">
                <Logo className="menu-container__logo" />
                <nav className="menu-container__menu">
                    <button onClick={ () => this.menuItemClickHandler('/') } className={ `menu-container__menu__item ${ pathname === '/' ? 'menu-container__menu__item--selected' : '' }` }>Inicio</button>
                    <button onClick={ () => this.menuItemClickHandler('/servicios') } className={ `menu-container__menu__item ${ pathname === '/servicios' ? 'menu-container__menu__item--selected' : '' }` }>Servicios</button>
                    <button onClick={ () => this.menuItemClickHandler('/quienes-somos') } className={ `menu-container__menu__item ${ pathname === '/quienes-somos' ? 'menu-container__menu__item--selected' : '' }` }>Quienes somos</button>
                    <button onClick={ () => this.menuItemClickHandler('/flotillas') } className={ `menu-container__menu__item ${ pathname === '/flotillas' ? 'menu-container__menu__item--selected' : '' }` }>Flotilla</button>
                    <button onClick={ () => this.menuItemClickHandler('/contactanos') } className={ `menu-container__menu__item ${ pathname === '/contactanos' ? 'menu-container__menu__item--selected' : '' }` }>Contacto</button>
                </nav>
                <img src={ VerifiedImage } alt="Verified" className="menu-container__verified" />
            </div>
        );
    }

}

export default withRouter( Menu );