import React, { Component } from 'react';
import Image from '../../assets/img/tr_imageW900_1.jpg';
import { ReactComponent as BackIcon } from '../../assets/icons/icon-back.svg';
import { ReactComponent as ExperienceIcon } from '../../assets/icons/experience.svg';

export default class WhoWeAre extends Component {

    render() {

        return(
            <section className="who-we-are" id="quienes-somos">
                <div className="who-we-are__left">
                    <h2 className="who-we-are__left__title">¿Quiénes somos?</h2>
                    <h3 className="who-we-are__left__subtitle">Conócenos</h3>
                    <p>
                        Transrental MX es líder en el arrendamiento de tractocamiones y remolques. Nuestra amplia flotilla, compuesta por unidades de última generación, garantiza soluciones de transporte eficientes y confiables para cubrir las necesidades específicas de nuestros clientes. Nos destacamos por nuestro compromiso con la calidad y la excelencia en el servicio, ofreciendo asesoramiento personalizado y construyendo relaciones sólidas con quienes confían en nosotros para sus necesidades de transporte. En Transrental MX, no solo proporcionamos vehículos, sino también soluciones integrales que impulsan el éxito de nuestros clientes en el mundo del transporte de carga. ¡Bienvenido a una asociación que lleva tu negocio hacia el futuro!
                    </p>

                    <div className="btn-container">

                        <button>
                            Saber más
                            <BackIcon className="back-icon"></BackIcon>
                        </button>

                        <ExperienceIcon></ExperienceIcon>
                    </div>
                </div>
                <div className="who-we-are__right">
                    <img src={ Image } alt="Truck"/>
                </div>
                
            </section>
        );
    }

}