import React, { Component } from 'react';
import { ReactComponent as BoxIcon } from '../../assets/icons/caja.svg';
import { ReactComponent as TracIcon } from '../../assets/icons/tractocamiones.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/icons/plataformas.svg';
import { ReactComponent as DollyIcon } from '../../assets/icons/dolly.svg';

export default class MobileSlider extends Component {

    constructor() {
        super();

        this.state = {
            currentPositionSlider: 0
        }

        this.getContainerClass = this.getContainerClass.bind( this );
        this.getSlideIcon = this.getSlideIcon.bind( this );
        this.getSlideTitle = this.getSlideTitle.bind( this );

        this.interval = setInterval( () => {
            const position = this.state.currentPositionSlider;

            this.setState({ currentPositionSlider: position !== 3 ? position + 1 : 0 });
        }, 5000);
    }

    componentWillUnmount() {

        clearInterval( this.interval );
    }

    getContainerClass() {

        switch( this.state.currentPositionSlider ) {
            case 0:
                return 'mobile-slider-container__slider--box';
            case 1:
                return 'mobile-slider-container__slider--trac';
            case 2:
                return 'mobile-slider-container__slider--platforms';
            case 3:
                return 'mobile-slider-container__slider--dollies';
            default:
                break;
        }
    }

    getSlideIcon() {

        switch( this.state.currentPositionSlider ) {
            case 0:
                return <BoxIcon></BoxIcon>;
            case 1:
                return <TracIcon></TracIcon>;
            case 2:
                return <PlatformsIcon></PlatformsIcon>;
            case 3:
                return <DollyIcon></DollyIcon>;
            default:
                break;
        }
    }

    getSlideTitle() {

        switch( this.state.currentPositionSlider ) {
            case 0:
                return 'Caja seca';
            case 1:
                return 'Tractocamión';
            case 2:
                return 'Plataformas';
            case 3:
                return 'Dolly';
            default:
                break;
        }
    }

    render() {

        return (
            <div className="mobile-slider-container">
                <div className={ `mobile-slider-container__slider ${ this.getContainerClass() }` }>
                    <p className="title">{ this.getSlideTitle() }</p>
                    { this.getSlideIcon() }
                </div>
                <div className="mobile-slider-container__slide-title">
                    { this.getSlideTitle() }
                </div>
            </div>
        );
    }
}