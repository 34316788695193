import React, { Component } from 'react';
import { ReactComponent as Logo } from '../../assets/img/logo_nuevo.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';

export default class Footer extends Component {

    render() {

        return (
            <div className="footer">
                <div className="logo-container">
                    <Logo className="logo-container__logo"></Logo>
                </div>
                <div className="info-bar">
                    {/* <p className="info-bar__first_item">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget.</p> */}
                    <ul className="info-bar__first_item">
                        <li>
                            <strong>Contactanos</strong>
                        </li>
                        <li>
                            <p>Somos tu mejor opcion</p>
                        </li>
                    </ul>
                    <ul className="info-bar__second_item">
                        <li>
                            <PhoneIcon className="icon"></PhoneIcon>
                            <p>Cel: 33 3849 7673</p>
                        </li>
                        <li>
                            <EmailIcon className="icon"></EmailIcon>
                            <p>rentame@transrental.mx</p>
                        </li>
                        <li>
                            <ClockIcon className="icon"></ClockIcon>
                            <p>LUNES A VIERNES 9:00 a.m. - 7:00 p.m. , SABADOS 9:00 a.m. - 1:00 p.m.</p>
                        </li>
                    </ul>
                    <ul className="info-bar__third_item">
                        <li>Preguntas frecuentes</li>
                        <li>Aviso de privacidad</li>
                        <li>Terminos y condiciones</li>
                    </ul>
                    <ul className="info-bar__fourth_item">
                        <ul>Facebook</ul>
                        <ul>Instagram</ul>
                    </ul>
                </div>
                <p className="reserved-rights">© 2019, Permatrol. &nbsp;&nbsp; Todos los derechos reservados. &nbsp;&nbsp; Por <strong>CACUMEN</strong></p>
            </div>
        );
    }
}