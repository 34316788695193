import React, { Component } from 'react';
import VMediaImage from '../../assets/img/vmedia.png';
import WalmartImage from '../../assets/img/walmart.png';
import CoralImage from '../../assets/img/coral.png';

export default class OurAllies extends Component {

    render() {

        return(
            <section className="our-allies">
                <h2 className="our-allies__title">Nuestros</h2>
                <h3 className="our-allies__subtitle">Aliados</h3>

                <div className="our-allies__allies-banner">
                    {/* <img src={ VMediaImage } alt="VMedia"/>
                    <img src={ WalmartImage } alt="Walmart"/>
                    <img src={ CoralImage } alt="Coral"/> */}
                </div>
            </section>
        );
    }
}