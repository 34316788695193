import React, { Component } from 'react';
import Image1 from '../../assets/img/main.png';
import Image2 from '../../assets/img/slider_tractocamion.png';
import Image3 from '../../assets/img/slider_plataforms.png';
import Image4 from '../../assets/img/slider_dolly.png';
import { ReactComponent as RedBox } from '../../assets/icons/caja_rojo.svg';
import { ReactComponent as RedTracto } from '../../assets/icons/tractocamiones_rojo.svg';
import { ReactComponent as RedPlatforms } from '../../assets/icons/plataformas_rojo.svg';
import { ReactComponent as RedDolly } from '../../assets/icons/dolly_rojo.svg';

export default class OurAllies extends Component {

    render() {

        return(
            <section className="flotillas">
                <div className="flotillas__flotillas-grid">
                    
                    <div className="flotillas__flotillas-grid__item">
                        <p className="flotillas__flotillas-grid__item__title">Caja seca</p>
                        <div className="flotillas__flotillas-grid__item__card">
                            <RedBox className="flotillas__flotillas-grid__item__card__icon"></RedBox>
                            <div className="flotillas__flotillas-grid__item__card__info">
                                <h4 className="flotillas__flotillas-grid__item__card__info--title">Características</h4>
                                <p className="flotillas__flotillas-grid__item__card__info--description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                                </p>
                            </div>
                            <img src={ Image1 } alt="1"/>
                        </div>
                    </div>
                    
                    <div className="flotillas__flotillas-grid__item">
                        <p className="flotillas__flotillas-grid__item__title">Tractocamión</p>
                        <div className="flotillas__flotillas-grid__item__card">
                            <RedTracto className="flotillas__flotillas-grid__item__card__icon"></RedTracto>
                            <div className="flotillas__flotillas-grid__item__card__info">
                                <h4 className="flotillas__flotillas-grid__item__card__info--title">Características</h4>
                                <p className="flotillas__flotillas-grid__item__card__info--description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                                </p>
                            </div>
                            <img src={ Image2 } alt="1"/>
                        </div>
                    </div>

                    <div className="flotillas__flotillas-grid__item">
                        <p className="flotillas__flotillas-grid__item__title">Plataforma</p>
                        <div className="flotillas__flotillas-grid__item__card">
                            <RedPlatforms className="flotillas__flotillas-grid__item__card__icon"></RedPlatforms>
                            <div className="flotillas__flotillas-grid__item__card__info">
                                <h4 className="flotillas__flotillas-grid__item__card__info--title">Características</h4>
                                <p className="flotillas__flotillas-grid__item__card__info--description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                                </p>
                            </div>
                            <img src={ Image3 } alt="1"/>
                        </div>
                    </div>

                    <div className="flotillas__flotillas-grid__item">
                        <p className="flotillas__flotillas-grid__item__title">Dollie</p>
                        <div className="flotillas__flotillas-grid__item__card">
                            <RedDolly className="flotillas__flotillas-grid__item__card__icon"></RedDolly>
                            <div className="flotillas__flotillas-grid__item__card__info">
                                <h4 className="flotillas__flotillas-grid__item__card__info--title">Características</h4>
                                <p className="flotillas__flotillas-grid__item__card__info--description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque.
                                </p>
                            </div>
                            <img src={ Image4 } alt="1"/>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}