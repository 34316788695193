import React, { Component } from 'react';

export default class WhoWeAreFull extends Component {

    render() {

        return(
            <section className="who-we-are-full">
                <h2 className="who-we-are-full__title">¿Quiénes somos?</h2>
                <h3 className="who-we-are-full__subtitle">Transrental</h3>

                <p>
                    Con el propósito de presentarnos y dar a conocer nuestros servicios como empresa responsable, dedicada al ARRENDAMIENTO de Remolques, Tracto Camiones y Fletes. Buscando siempre el fin de ofrecerle el mejor equipo de transportación acorde a sus necesidades y con el mejor costo posible, en todo México. Esta empresa mexicana surge debido a la demanda del Sector Industrial y Comercial de la Región, lo cual nos permite implementar y desarrollar nuestro interés, en el ámbito del arrendamiento y fletes de manera responsable y siempre con el compromiso de que nuestros servicios cumplan con la calidad y exigencia, de nuestros CLIENTES.
                <br/>
                <br/>
                    Estamos ubicados en la ciudad de Guadalajara, Jal. Contamos con la experiencia, equipos de carga y motorizado necesarios; nuestro personal cuenta con la capacitación que exigen las normas reglamentarias con GPS, Recurso Confiable (RC) Licencias Verificadas, Exámenes Psicométricos y Estudios Antidoping continuos, además de monitoreo las 24 hrs. Vía celular y radio.
                </p>
            </section>
        );
    }
}