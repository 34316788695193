import React, { Component } from 'react';

export default class ContactFull extends Component {

    render() {

        return(
            <section className="contact-full">
                <h3 className="contact-full__title">Contáctanos</h3>

                <p>Estamos disponibles de LUNES A VIERNES 9:00 a.m. - 7:00 p.m. , SABADOS 9:00 a.m. - 1:00 p.m.</p>
                <p>Cel: 33 3849 7673</p>
                <p>rentame@transrental.mx</p>
            </section>
        );
    }
}