import React, { Component } from 'react';
import MainHeaderContainer from './MainHeaderContainer';
import MobileSlider from './MobileSlider';
import MobileMenuBar from './MobileMenuBar';
import MobileMenu from './MobileMenu';
import { ReactComponent as Phone } from '../../assets/icons/phone.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import MenuWithoutSlider from './MenuWithoutSlider';

class Header extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor() {
        super();
        
        this.state = {
            showMobileMenu: false
        }
    }

    render() {
        const { pathname } = window.location;

        return (
            <header>
                { this.state.showMobileMenu
                    && <MobileMenu
                            categories={ this.state.categories }
                            closeMenu={ () => this.setState({ showMobileMenu: false }) }></MobileMenu> }
                <MobileMenuBar openMenu={ () => this.setState({ showMobileMenu: true }) }></MobileMenuBar>
                <InformationBar></InformationBar>
                { pathname !== '/' && <MenuWithoutSlider></MenuWithoutSlider> }
                { pathname === '/' && <MainHeaderContainer></MainHeaderContainer> }
                { pathname === '/' &&  <SliderTitles></SliderTitles> }
                { pathname === '/' && <MobileSlider></MobileSlider> }
            </header>
        );
    }

}

function InformationBar() {

    return (
        <div className="info-bar" id="inicio">
            <p className="info-bar__phone-information">
                <span className="info-bar__phone-information--text"><strong>CONTACTANOS </strong></span>
                <span className="info-bar__phone-information--mobile-text"><strong>CONTACTANOS </strong><br/> Cel: 33 3849 6440</span>
            </p>

            <p className="info-phone-information">
                <Phone className="info-bar__phone-information--icon"></Phone>
                <span className="info-bar__phone-information--text">Cel: 33 3849 7673</span>
            </p>

            <p className="info-bar__email-information">
                <Email className="info-bar__email-information--icon"></Email>
                <span className="info-bar__email-information--text">rentame@transrental.mx</span>
            </p>

            <p className="info-bar__time-information">
                <Clock className="info-bar__time-information--icon"></Clock>
                <span className="info-bar__time-information--text">LUNES A VIERNES 9:00 a.m. - 7:00 p.m. , SABADOS 9:00 a.m. - 1:00 p.m.</span>
            </p>
        </div>
    );
}

function SliderTitles() {

    return (
        <ul className="slider-titles">
            <li className="slider-titles__item">Cajas secas</li>
            <li className="slider-titles__item">Tractocamiones</li>
            <li className="slider-titles__item">Plataformas</li>
            <li className="slider-titles__item">Dollies</li>
        </ul>
    );
}

export default withRouter( Header );